var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',{staticClass:"primary--border",attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_vm._v(" Marks Entry "),_c('v-spacer')],1),_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',{staticClass:"title"},[_c('v-flex',{attrs:{"xs3":"","sm4":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"outlined":"","dense":"","loading":_vm.examLoading,"disabled":!_vm.exams.length,"items":_vm.exams,"label":"Exam"},model:{value:(_vm.exam),callback:function ($$v) {_vm.exam=$$v},expression:"exam"}})],1),_c('v-flex',{attrs:{"xs3":"","sm2":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"outlined":"","dense":"","loading":_vm.gradeLoading,"hint":_vm.selectedEvaluation,"persistent-hint":"","items":_vm.grades,"label":"Grade","disabled":!_vm.exam || !_vm.grades.length},model:{value:(_vm.grade),callback:function ($$v) {_vm.grade=$$v},expression:"grade"}})],1),_c('v-flex',{attrs:{"xs3":"","sm2":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"outlined":"","dense":"","items":_vm.sections,"label":"Section","disabled":!_vm.grade},model:{value:(_vm.section),callback:function ($$v) {_vm.section=$$v},expression:"section"}})],1),_c('v-flex',{attrs:{"xs3":"","sm3":""}},[_c('v-select',{staticClass:"pa-0",attrs:{"outlined":"","dense":"","items":_vm.subjects,"loading":_vm.subjectLoading,"label":"Subjects","disabled":!_vm.grade || !_vm.subjects.length},model:{value:(_vm.subject),callback:function ($$v) {_vm.subject=$$v},expression:"subject"}})],1)],1),_c('refresh-button',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.items.data.length),expression:"form.items.data.length"}],on:{"action":function($event){return _vm.get()}}})],1),_c('br'),(
          _vm.form.items.data.length &&
            !Object.keys(_vm.subject_marks).length &&
            _vm.form.items.data.length
        )?_c('v-container',[_c('strong',{staticStyle:{"color":"#ff5252","font-size":"16px"}},[_c('v-icon',{attrs:{"color":"#ff5252"}},[_vm._v("warning")]),_vm._v(" "+_vm._s("Subject marks has not been set for " + _vm.subjectName)+" ")],1)]):_vm._e(),(_vm.form.items.data.length && Object.keys(_vm.subject_marks).length)?_c('div',{staticClass:"data-represent"},[_c('div',[_c('span',{staticClass:"d-success"}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.subject_marks.theory))]),_c('small',[_vm._v(" Theory")])]),_c('div',[_c('span',{staticClass:"d-primary"}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.subject_marks.practical))]),_c('small',[_vm._v(" Practical")])]),_c('div',[_c('span',{staticClass:"d-error"}),_vm._v(" "),_c('strong',[_vm._v(_vm._s(_vm.absentStudentsCount))]),_c('small',[_vm._v(" Absent")])]),_c('div',{staticStyle:{"float":"right","margin-left":"auto","margin-right":"40px","color":"#f48e52"}},[(!_vm.verify_status.is_verify && _vm.marks_entry.allowed)?_c('strong',[_c('v-icon',{attrs:{"small":"","color":"#f48e52"}},[_vm._v("check_box")]),_vm._v(" Check to mark Absent. ")],1):_vm._e(),(!_vm.verify_status.is_verify && !_vm.marks_entry.allowed)?_c('strong',{staticStyle:{"color":"#ff5252"}},[_c('v-icon',{attrs:{"small":"","color":"#ff5252"}},[_vm._v("close")]),_vm._v(" Marks entry is not allowed until "+_vm._s(_vm.marks_entry.duration.start)+". ")],1):_vm._e(),(_vm.verify_status.is_verify === 1)?_c('strong',{staticStyle:{"color":"#777","font-size":"14px","font-weight":"normal"}},[_c('v-icon',{attrs:{"small":"","color":"#4caf50"}},[_vm._v("check_circle")]),_vm._v(" Marks Verified by "),_c('strong',{staticStyle:{"color":"#444"}},[_vm._v(" "+_vm._s(_vm.verify_status.details.verified_by)+" ")])],1):_vm._e()])]):_vm._e(),_c('v-card-actions',[(
            !_vm.verify_status.is_verify &&
              Object.keys(_vm.subject_marks).length &&
              _vm.form.items.data.length > 0 &&
              _vm.$auth.can('verify-marks-exam|exam-verify-marks')
          )?_c('v-checkbox',{staticStyle:{"margin-right":"20px"},attrs:{"color":"blue"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticStyle:{"color":"#333"}},[_vm._v(" Check to verify subject marks "),_c('p',{staticStyle:{"line-height":"10px"}},[_c('small',{staticStyle:{"color":"#666","font-size":"10px"}},[_vm._v(" Caution: Subject marks once verified won't be editable in future. ")])])])]},proxy:true}],null,false,3462294633),model:{value:(_vm.is_verify),callback:function ($$v) {_vm.is_verify=$$v},expression:"is_verify"}}):_vm._e(),(
            _vm.verify_status.is_verify &&
              Object.keys(_vm.subject_marks).length &&
              _vm.form.items.data.length > 0 &&
              _vm.$auth.can('verify-marks-exam|exam-verify-marks')
          )?_c('v-btn',{on:{"click":function($event){return _vm.unVerifyMarks()}}},[_vm._v("Unverify ")]):_vm._e(),_c('v-spacer'),(
            !_vm.verify_status.is_verify &&
              Object.keys(_vm.subject_marks).length &&
              _vm.form.items.data.length > 0 &&
              _vm.$auth.can('marks-input-create&marks-input-update')
          )?_c('v-btn',{attrs:{"color":"success","outline":"","disabled":_vm.dataVerfiy},on:{"click":_vm.save}},[_vm._v("Save ")]):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }